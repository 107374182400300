export default class TriggerPolicy {
  beforeAll(_, user) {
    if (!user) {
      return false
    }

    if (user.data.role === 'admin') {
      return true
    }
  }

  /**
   * Check if user can list triggers
   *
   * @param {User} user
   */
  list(user) {
    return (
      user.data.role === 'manager' ||
      (user.data.role === 'content_team' && user.data.triggers_enabled)
    )
  }

  /**
   * Can approve/reject submitted triggers
   */
  manage(user) {
    return user.data.role === 'manager'
  }

  /**
   * Check if can create trigger
   *
   * @param {User} user
   */
  create(user) {
    return (
      user.data.triggers_enabled &&
      (user.data.role === 'coach' || user.data.role === 'operator')
    )
  }

  /**
   * Check if can edit trigger
   */
  edit(user) {
    return user.data.role === 'manager'
  }
}
