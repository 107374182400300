<template>
  <ul class="menu-list">
    <li>
      <nuxt-link
        to="/"
        active-class="tw-text-white"
        exact
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
      >
        <BlocksIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Dashboard</span>
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Trigger')"
        to="/triggers"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
        ><TriggerIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Triggers</span></nuxt-link
      >
    </li>
    <li>
      <nuxt-link
        v-if="$can('list', 'Profile')"
        to="/profiles"
        active-class="tw-text-white"
        class="tw-font-medium tw-text-sm hover:tw-text-white hover:tw-bg-transparent tw-flex tw-items-center"
        ><GroupIcon class="tw-mr-2" />
        <span v-if="!displayIconsOnly">Profiles</span></nuxt-link
      >
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BlocksIcon from '@/components/icon/BlocksIcon.vue'
import TriggerIcon from '@/components/icon/TriggerIcon.vue'
import GroupIcon from '@/components/icon/GroupIcon.vue'

export default {
  components: {
    BlocksIcon,
    TriggerIcon,
    GroupIcon
  },

  props: {
    displayIconsOnly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      submenus: {
        logs: false
      }
    }
  },

  computed: {
    ...mapGetters({
      isSidebarCollapsed: 'sidebar/isSidebarCollapsed'
    })
  },

  methods: {
    toggleSubmenu(submenu) {
      for (const key in this.submenus) {
        if (key !== submenu) {
          this.submenus[key] = false
        }
      }

      this.submenus[submenu] = !this.submenus[submenu]
      this.setSidebar(false)
    },
    ...mapActions({
      setSidebar: 'sidebar/setSidebar'
    })
  }
}
</script>
