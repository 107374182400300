<template>
  <div v-if="$auth.user" class="navbar-start">
    <nuxt-link
      v-if="$can('list', 'Trigger')"
      to="/triggers"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
      >Triggers</nuxt-link
    >
    <nuxt-link
      v-if="$can('list', 'Profile')"
      to="/profiles"
      class="navbar-item tw-text-left is-hidden-desktop tw-font-medium tw-text-washed hover:tw-text-black"
      >Profiles</nuxt-link
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {},
  data() {
    return {
      boostedData: {}
    }
  },
  computed: {
    ...mapGetters({
      getFaqViews: 'faq-views/getFaqViews'
    }),
    faqViews() {
      return this.getFaqViews
    },
    newsCount() {
      return this.$store.state.news.news_count
    }
  },
  mounted() {
    this.fetchBoostedStatus()
    this.fetchFaqViews()
  },
  methods: {
    async fetchBoostedStatus() {
      try {
        const response = await this.$axios.get('/operator/boosted-status')
        this.boostedData = {
          boosted: response.data.boosted,
          date: response.data.date
        }
      } catch (error) {
        console.error('Error fetching boosted status', error)
      }
    },
    ...mapActions({
      fetchFaqViews: 'faq-views/fetchFaqViews'
    })
  }
}
</script>
