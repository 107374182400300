const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['coach'] = require('../middleware/coach.js')
middleware['coach'] = middleware['coach'].default || middleware['coach']

middleware['contentTeam'] = require('../middleware/contentTeam.js')
middleware['contentTeam'] = middleware['contentTeam'].default || middleware['contentTeam']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['manager'] = require('../middleware/manager.js')
middleware['manager'] = middleware['manager'].default || middleware['manager']

middleware['preventTrialCoach'] = require('../middleware/preventTrialCoach.js')
middleware['preventTrialCoach'] = middleware['preventTrialCoach'].default || middleware['preventTrialCoach']

export default middleware
