import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dd0db484 = () => interopDefault(import('../pages/account-requests/index.vue' /* webpackChunkName: "pages/account-requests/index" */))
const _d36df51e = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _20a6ad9b = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _d44e0620 = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _2290901f = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _015b9d60 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _3072d5b7 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _7586b47a = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _3361d90a = () => interopDefault(import('../pages/financial-metrics/index.vue' /* webpackChunkName: "pages/financial-metrics/index" */))
const _6bb50671 = () => interopDefault(import('../pages/ht-alerts.vue' /* webpackChunkName: "pages/ht-alerts" */))
const _6cccfc80 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _7cfb2d0f = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _d05786ec = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _26e9193c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _30b016fb = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _3e191129 = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _5be5d4b2 = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _5ef2af3a = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _2b5b465b = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _cd802f4c = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _27971ced = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _0f87d061 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _c904b39a = () => interopDefault(import('../pages/operator-details/index.vue' /* webpackChunkName: "pages/operator-details/index" */))
const _433b1cb5 = () => interopDefault(import('../pages/operator-traffic-dashboard/index.vue' /* webpackChunkName: "pages/operator-traffic-dashboard/index" */))
const _7045dc88 = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _2ffab330 = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _1aae0d3c = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _7b2595c4 = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _63635e3a = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _23051a86 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _5d34b21b = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _69238366 = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _d80aed6a = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _8edc0b56 = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _56a5dfb5 = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _1e8240b8 = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _48d6ecbd = () => interopDefault(import('../pages/dashboard/productivity-metrics/index.vue' /* webpackChunkName: "pages/dashboard/productivity-metrics/index" */))
const _a8ddc292 = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _2c7716ae = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _7a23a29f = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _d62b378e = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _3d09eaaa = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _0478b254 = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _11416d7a = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _79539d89 = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _6f657634 = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _4b0507f0 = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _771f8a1c = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _0d3921da = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _bb39d40c = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _a236848c = () => interopDefault(import('../pages/profiles/request-update/request-update-mixin.js' /* webpackChunkName: "pages/profiles/request-update/request-update-mixin" */))
const _7da68bb8 = () => interopDefault(import('../pages/profiles/request-update/_profile.vue' /* webpackChunkName: "pages/profiles/request-update/_profile" */))
const _20811fd8 = () => interopDefault(import('../pages/account-requests/_request.vue' /* webpackChunkName: "pages/account-requests/_request" */))
const _17606c70 = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _4d878618 = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _f2f41964 = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _1436aa53 = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _d530662a = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _4066616f = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _452766c6 = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _a0b8e384 = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _47d7260d = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _1e28b450 = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _b0a85b14 = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _175a2747 = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _5f188f90 = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _22e3d04b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-requests",
    component: _dd0db484,
    name: "account-requests"
  }, {
    path: "/ai-reports",
    component: _d36df51e,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _20a6ad9b,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _d44e0620,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _2290901f,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _015b9d60,
    name: "dashboard"
  }, {
    path: "/faqs",
    component: _3072d5b7,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _7586b47a,
    name: "feedback-funnel"
  }, {
    path: "/financial-metrics",
    component: _3361d90a,
    name: "financial-metrics"
  }, {
    path: "/ht-alerts",
    component: _6bb50671,
    name: "ht-alerts"
  }, {
    path: "/invoices",
    component: _6cccfc80,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _7cfb2d0f,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _d05786ec,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _26e9193c,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _30b016fb,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _3e191129,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _5be5d4b2,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _5ef2af3a,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _2b5b465b,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _cd802f4c,
    name: "my-team"
  }, {
    path: "/news",
    component: _27971ced,
    name: "news"
  }, {
    path: "/notifications",
    component: _0f87d061,
    name: "notifications"
  }, {
    path: "/operator-details",
    component: _c904b39a,
    name: "operator-details"
  }, {
    path: "/operator-traffic-dashboard",
    component: _433b1cb5,
    name: "operator-traffic-dashboard"
  }, {
    path: "/payment-updates",
    component: _7045dc88,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _2ffab330,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _1aae0d3c,
    name: "profile"
  }, {
    path: "/profiles",
    component: _7b2595c4,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _63635e3a,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _23051a86,
    name: "settings"
  }, {
    path: "/stats",
    component: _5d34b21b,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _69238366,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _d80aed6a,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _8edc0b56,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _56a5dfb5,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _1e8240b8,
    name: "wink-replies"
  }, {
    path: "/dashboard/productivity-metrics",
    component: _48d6ecbd,
    name: "dashboard-productivity-metrics"
  }, {
    path: "/manuals/new",
    component: _a8ddc292,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _2c7716ae,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _7a23a29f,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _d62b378e,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _3d09eaaa,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _0478b254,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _11416d7a,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _79539d89,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _6f657634,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _4b0507f0,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _771f8a1c,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _0d3921da,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _bb39d40c,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/profiles/request-update/request-update-mixin",
    component: _a236848c,
    name: "profiles-request-update-request-update-mixin"
  }, {
    path: "/profiles/request-update/:profile?",
    component: _7da68bb8,
    name: "profiles-request-update-profile"
  }, {
    path: "/account-requests/:request?",
    component: _20811fd8,
    name: "account-requests-request"
  }, {
    path: "/activity-logs/:type?",
    component: _17606c70,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _4d878618,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _f2f41964,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _1436aa53,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _d530662a,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _4066616f,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _452766c6,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _a0b8e384,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _47d7260d,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _1e28b450,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _b0a85b14,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _175a2747,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _5f188f90,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _22e3d04b,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
