var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"navbar tw-bg-body tw-shadow operator-nav is-fixed-top",class:_vm.pageWidth,attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"app-container container"},[_vm._m(0),_vm._v(" "),(_vm.$auth.loggedIn)?_c('div',{staticClass:"navbar-menu"},[(_vm.$auth.user.data.role == 'operator')?_c('NavOperator'):_vm._e(),_vm._v(" "),(_vm.$auth.user.data.role == 'manager')?_c('NavManager'):_vm._e(),_vm._v(" "),(_vm.$auth.user.data.role == 'content_team')?_c('NavContentTeam'):_vm._e(),_vm._v(" "),(_vm.$auth.user.data.role == 'coach')?_c('NavCoach'):_vm._e(),_vm._v(" "),(_vm.$auth.user.data.role == 'trial_coach')?_c('NavTrialCoach'):_vm._e(),_vm._v(" "),(_vm.$auth.user.data.role == 'country_manager')?_c('NavCountryManager'):_vm._e(),_vm._v(" "),(_vm.$auth.user.data.role == 'admin')?_c('NavAdmin'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"navbar-end"},[(
              _vm.$auth.user.data.role == 'operator' ||
                _vm.$auth.user.data.role == 'coach' ||
                _vm.$auth.user.data.role == 'trial_coach'
            )?_c('OperatorEarning',{staticClass:"navbar-item tw-mr-14"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"navbar-item tw-flex tw-items-center tw-content-center"},[_c('div',[_c('NotificationsDropdown'),_vm._v(" "),_c('b-dropdown',{attrs:{"position":"is-bottom-left","triggers":['hover']},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"tw-text-washed hover:tw-text-black tw-leading-none tw-p-2 hover:tw-bg-gray-100 tw-rounded",attrs:{"role":"button"}},[_c('UserIcon')],1)]},proxy:true}],null,false,3396522752)},[_vm._v(" "),_c('b-dropdown-item',{staticClass:"tw-p-0",attrs:{"custom":""}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start tw-border-b tw-px-4 tw-py-2"},[_c('div',{staticClass:"tw-font-medium tw-text-black"},[_vm._v("\n                      "+_vm._s(_vm.$auth.user.data.first_name)+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"tw-text-washed tw-text-sm tw-text-left"},[_vm._v("\n                      "+_vm._s(_vm.$auth.user.data.email)+"\n                    ")])])]),_vm._v(" "),_c('b-dropdown-item',{attrs:{"has-link":""}},[_c('nuxt-link',{attrs:{"to":"/profile"}},[_vm._v("\n                    Profile\n                  ")])],1),_vm._v(" "),_c('b-dropdown-item',{attrs:{"has-link":""}},[_c('a',{on:{"click":_vm.logout}},[_vm._v("Logout")])])],1)],1)])],1)],1):_vm._e()])]),_vm._v(" "),(
      _vm.$auth.loggedIn &&
        (_vm.$auth.user.data.role == 'manager' ||
          _vm.$auth.user.data.role == 'admin' ||
          _vm.$auth.user.data.role == 'coach')
    )?_c('AdminStats'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-brand tw-items-center is-hidden-desktop"},[_c('a',{staticClass:"navbar-item tw-text-xl tw-font-medium tw-py-6",attrs:{"href":"/"}},[_vm._v("Operator Dashboard")]),_vm._v(" "),_c('a',{staticClass:"navbar-burger burger",attrs:{"role":"button","aria-label":"menu","aria-expanded":"false","onclick":"document.querySelector('.navbar-menu').classList.toggle('is-active');","data-target":"navbarBasicExample"}},[_c('span',{attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{attrs:{"aria-hidden":"true"}})])])}]

export { render, staticRenderFns }